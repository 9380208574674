<template>
    <div>
        <div v-if="authUserPermission['sap-sync-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('sap_sync') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="sync.search" :placeholder="tt('search')" v-on:keyup="filter"/>                            
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="!onLoad">
                            <div class="row">
                                <div v-for="(sync, i) in sync.data" class="col-xl-3 col-md-6">
                                    <div class="card bg-gradient-primary border-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <h5 class="card-title text-uppercase text-muted mb-0 text-white">{{sync.menu}}</h5>
                                                    <span class="h2 font-weight-bold mb-0 text-white">{{sync.last_sync_at}}</span>
                                                </div>
                                                <div class="col-auto">                                                    
                                                    <button data-toggle="dropdown" class="btn-rotate btn btn-sm btn-neutral mr-0" v-on:click="synchronize(i)" :disabled="(onSync[i] == 1)">                   
                                                        <i class="fas fa-sync" v-if="!onSync[i]"></i>
                                                        <i class="fas fa-sync fa-spin" v-else></i>
                                                    </button>                                                                                                            
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <skeleton-loading v-else/>
                    </div>
                </div>
            </div>            
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapIntegration from '@/services/sap/sapIntegration.service';    
    
    export default {        
        data() {
            return {                 
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },             
                form: {
                    add: true,
                    title: "Create Department",
                    show: false
                }, 
                sync: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',
                    data: []
                },     
                onSync: [],
                errorMessage: null,   
                department: {},
                holdingCode: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, sapIntegration.sync({search: this.sync.search})).onSuccess(function(response) {                        
                    context.sync.data = response.data.data;  
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.sync.data = [];                        
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            filter() {
                if(this.sync.search != this.$route.query.search){
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.sync.search == ''){
                            this.$router.push({path:'/sap/sap-sync', query:null})
                        }else{
                            this.$router.push({path:'/sap/sap-sync', query:{search:this.sync.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            synchronize(i) {
                this.$set(this.onSync, i, 1);
                let context = this
                Api(context, sapIntegration.execute(this.sync.data[i].sap_sync_id)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onError(function(error) {                    
                   if (error.response) {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });           
                    }
                }).onFinish(function(){
                    context.$set(context.onSync, i, 0);
                }).call();
            }
        }   
    };
</script>
